/* Sidebar Container */
.sidebar {
    position: fixed;
    top: 7em;
  display: none; /* Initial position (hidden) */
    width: 100%;
    height: 100%;
    /* padding-right: 70px; */
    background-color: #ffffe3; /* Sidebar background color */
    /* Adjust the styles to fit your design */
    /* transition: 0.8s ease; */

  }
  
  /* Open the Sidebar */
  .sidebar.open {
    display: flex;
    flex-direction: column;
    /* padding-right: 70px; */
    align-items: center;
    justify-content: center;


  }
  
  /* Sidebar Content */
  .sidebar-content {
    font-size: 30px;
    font-weight: 500;
    color: #000; /* Text color inside the sidebar */
    padding-bottom: 10rem;
  }
  
  /* Sidebar List Items (Menu Items) */
  .sidebar ul {
    list-style: none;

}

.sidebar ul li {
    cursor: pointer;
    transition: color 0.2s ease, padding 0.2s ease; /* Add color and padding transitions on hover */
    margin: 2rem;
    border: 2px #000 solid;
    padding: 10px 30px; /* Increased padding on hover to make the background bigger */
}

.sidebar ul li:hover {
    color: #ffffe3; /* Change text color on hover */
    background-color: #000;
    padding: 20px 40px; /* Increased padding on hover to make the background bigger */
}

  
  /* Close Button (Optional) */
  .close-button {
    text-align: right;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .close-button:hover {
    color: #ffffe3;
  }
  

  @media (min-width: 768px) {
    .sidebar.open {
      display: none;
    }
  }