.full-screen-main {
  height: calc(100vh - 7rem);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.full-screen-content {
  height: 100%;
  width: 100%;
  display: flex;
  gap: 3vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  /* overflow: hidden; */
}

.hi-i-m-nichita {
  color: #000000;
  font-weight: 400;
  text-align: center;
  margin: 0;
  width: 100%;
}

.half-bold {
  font-weight: bold;
}

.buttons-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 15%;
  margin-right: 15%;
}

.buttons-box-content {
  background: rgba(255, 255, 227, 0.98);
  /* backdrop-filter: blur(10px); */
  border: 2px solid;

  border-radius: 50px;
  /* background: rgba(255, 255, 227, 1); */

  /* box-shadow: -7.94667px 7.94667px 7.94667px 0px rgba(204, 204, 182, 0.60) inset, -3.97333px 3.97333px 3.97333px 0px rgba(255, 255, 227, 0.60) inset, 7.94667px -7.94667px 7.94667px 0px rgba(255, 255, 227, 0.60) inset, 3.97333px -3.97333px 3.97333px 0px rgba(204, 204, 182, 0.60) inset;*/
  /* backdrop-filter: blur(20px); */

  /* border-radius: 10vw; */
  box-shadow: 8px 8px #000;
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  align-items: center;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 5vh;
  padding-bottom: 5vh;
  position: relative;
  /* animation: shadowChange1 4s infinite; */
}

@keyframes shadowChange1 {
  0% {
    box-shadow: 10px 10px #bafbb0; /* Initial color */
    border-color: #bafbb0;
  }
  50% {
    box-shadow: 10px 10px #e7e765; /* Color change at 25% of the animation */
    border-color: #e7e765;
  }
  100% {
    box-shadow: 10px 10px #bafbb0; /* Color change at 50% of the animation */
    border-color: #bafbb0;
  }
}

@media (max-width: 768px) {
  .buttons-box-content {
    flex-direction: column;
  }
}

.button-main {
  display: flex;
  flex-direction: row;
  padding: 3vh 3vw;
  gap: 2vw;
  /* cursor: pointer; */
  align-items: center;
  background-color: #000000;
  border-radius: 200px;
  /* box-shadow: 5px 5px 0px #bafbb0; */
  animation: shadowChange 4s infinite;
  box-shadow: 5px 5px #bafbb0;
}

.button-main:hover {
  box-shadow: 5px 5px #299e17, 10px 10px #e1e12f;
  animation: none;
  transition: 0.3s ease;
}

.button-main-text {
  color: #ffffe3;
  font-weight: 600;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .glow-on-hover {
    font-size: 1.2rem;
  }
}

@media (min-width: 769px) {
  .glow-on-hover {
    font-size: 1.5rem;
  }
}

@keyframes shadowChange {
  0% {
    box-shadow: 5px 5px #bafbb0; /* Initial color */
  }
  50% {
    box-shadow: 5px 5px #e7e765; /* Color change at 25% of the animation */
  }
  100% {
    box-shadow: 5px 5px #bafbb0; /* Color change at 50% of the animation */
  }
}

.wrap-text {
  white-space: normal;
  margin: 0;
}

.no-wrap-text {
  white-space: nowrap;
  margin: 0;
}

.glow-on-hover {
  border: none;
  outline: none;
  color: #ffffe3;
  background: #111;
  position: relative;
  z-index: 0;
  font-weight: 600;
  border-radius: 200px;
  display: flex;
  flex-direction: row;
  padding: 3vh 3vw;
  gap: 2vw;
  /* cursor: pointer; */
  cursor: none;
  align-items: center;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .glow-on-hover {
    cursor: pointer;
  }
}


.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -4px;
  left: -4px;
  background-size: 400%;
  z-index: -1;
  filter: blur(10px);
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  animation: glowing 20s linear infinite;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  border-radius: 200px;
}

.glow-on-hover:active::before {
  color: #ffffe3;
  background: #fff111;
}

.glow-on-hover:active:after {
  background: #fff;
  color: #fff;
}

.glow-on-hover:hover::after {
  background: transparent;
  color: #fff;
}

.glow-on-hover:hover {
  color: #000;
  scale: 1.05;
  transition: 0.5s ease;
}

.glow-on-hover:hover:before {
  opacity: 1;
  color: #fff;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  left: 0;
  top: 0;
  border-radius: 200px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
