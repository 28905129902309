
  .menu-button {
    cursor: pointer;
    width: 40px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.3s;
  }
  
  .bar {
    width: 40px;
    height: 4px;
    background-color: #333;
    transition: 0.3s;
  }
  
  .menu-button.open .bar:nth-child(1) {
    transform: translateY(13px) rotate(45deg);
  }
  
  .menu-button.open .bar:nth-child(2) {
    opacity: 0;
  }
  
  .menu-button.open .bar:nth-child(3) {
    transform: translateY(-12.5px) rotate(-45deg);
  }
  