.ticker-container-vertical {
  position: fixed;
  top: 0;
  right: 0;
  width: 70px; /* Adjusted to be 10px wider than before */
  height: 100%;
  background-color: #ffffe3;
  z-index: 2; /* Set a higher z-index for the right sidebar */
  overflow: hidden; /* Ensure the container clips text properly */
  writing-mode: vertical-rl;
  text-align: center;
  display: flex;
  align-items: center; /* Center the text vertically */
  white-space: nowrap; /* Prevent line breaks */
}

.ticker-text-vertical {
  color: #000;
  font-size: 1.8rem;
  font-weight: 500;
  display: inline-block;
  animation: ticker-vertical 10s linear infinite;
}

@keyframes ticker-vertical {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}

.rectangle-vertical {
  color: #ffffe3;
  background: #111;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  position: absolute;
  animation: colorChange 4s infinite;
}


/* .rectangle-vertical:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: 0;
  left: 0;
  background-size: 400%;
  width: 5px;
  height: 100%;
  z-index: -1;
  animation: glowing 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  overflow: hidden;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
} */


@keyframes colorChange {
  0% {
    background-color: black; /* Initial color */
    width: 4px;
  }
  50% {
    background-color: #bafaaf; /* Color change at 25% of the animation */
    width: 6px;
  }
  100% {
    background-color: black; /* Color change at 50% of the animation */
    width: 4px;
  }
}

@media (max-width: 450px) {
  .ticker-container-vertical {
    width: 40px;
  }
}