.header {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 70px);
  height: 7rem;
  background-color: #ffffe3;
  z-index: 1;
  transition: background-color 0.1s ease, border 0.1s ease;
  /* border-bottom: 1px solid #b0b49a; */
}

.header.scrolled {
  background-color: #ffffe3;
  transition: 0.5s ease;
  box-shadow: 0px 5px #000;
  border-bottom: 1px solid #b0b49a;
  animation: sssChange 4s infinite;
  /* display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 70px);
  height: 7rem;
  z-index: 1; */
}

@keyframes sssChange {
  0% {
    box-shadow: 0px 5px #000; /* Initial color */
    transition: 0.5s ease;

  }
  50% {
    box-shadow: 0px 5px #bafaaf;/* Color change at 25% of the animation */
    transition: 0.5s ease;

  }
  100% {
    box-shadow: 0px 5px #000;/* Color change at 50% of the animation */
    transition: 0.5s ease;

  }
}

.frame {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
  position: relative;
}

.logo {
  align-items: center;
  align-self: stretch;

  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  padding: 0.5rem 1.5rem;
  position: relative;
}

.div {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 15px;
  justify-content: center;
  position: relative;
}

.vector {
  height: 50px;
  position: relative;
  width: 35px;
}

.header-tab {
  align-items: center;
  align-self: stretch;

  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 71px;
  /* cursor: pointer; */
  justify-content: center;
  position: relative;
  transition: background-color 0.3s ease;
}

/* .header-tab:hover::after {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
bottom: 0;
  background-size: 400%;
  width: 100%;
  height: 4px;
  animation: glowing 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
} */

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/* .header-tab:hover {
  background-color: #000000;
}

.header-tab:hover .header-tabs {
  color: #ffffe3;
} */

.header-tabs {
  color: #000000;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
  transition: color 0.3s ease;
}

.portfolio {
  align-items: center;
  align-self: stretch;

  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 78px;
  justify-content: center;
  position: relative;
}

.cv {
  align-items: center;
  align-self: stretch;

  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 70px;
  justify-content: center;
  position: relative;
}

.phone {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  /* gap: 5rem; */
  padding: 8px 24px;
  justify-content: center;
  position: relative;
}

.phone.scrolled {
  border-left: 1px #b0b49a solid;
}

.header-text {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.burger-icon {
  display: none;
  /* cursor: pointer; */
  font-size: 24px;
  margin: 40px;
}

@media (min-width: 769px) {
  .header .header-tab,
  .header .frame-wrapper1,
  .header .phone {
    display: flex;
  }
}

@media (max-width: 768px) {
  .header .header-tab,
  .header .frame-wrapper1,
  .header .phone {
    display: none;
  }
  .burger-icon {
    display: block;
  }
}

@media (max-width: 450px) {
  .header,
  .header.scrolled {
    width: calc(100% - 40px);
  }
}

@media (min-width: 451px) {
  .header,
  .header.scrolled {
    width: calc(100% - 70px);
  }
}

.logo.scrolled {
  border-right: 1px solid #b0b49a;
}
