
  
  .background-waves {
    position: absolute;
    /* top: calc(0 - 14vh); */
    width: 100%;
    max-width: 800px;
    /* height: auto; */
    /* top: -20%; */
    /* max-height: 700px; */
    z-index: -1;
    /* height: 100%; */
    /* bottom: -150px; */
    /* bottom: 0px; */
    top: 300px;
    left: 0;
  
    rotate: 180deg;
 scale: 1;
    /* top: 0;
    left: 0; */
    /* align-self: flex-end;
    justify-self: flex-end; */
    /* justify-content: bottom;
    align-items: bottom; */
  }