/* .https-lottiefiles-wrapper {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
  }
   */
  .https-lottiefiles {
width: 70px;
    position: relative;
    padding-bottom: 1vw;

  }