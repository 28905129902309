/* CSS for the updated Footer component */

.footer-container {
    align-items: center;
    justify-content: center;
    padding: 50px;
    position: relative;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    border-top: 1px solid #000;
  }
  
  .contact-info {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
    flex-direction: row;
    /* justify-content: center; */
    align-content: center;
    align-items: center;
    text-align: center;
    column-gap: 5vw;
    row-gap: 2vw;
  }
  
  .contact-details {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 15px;
    position: relative;
  }
  
  .contact-title {
    color: #000000;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .contact-info-details {
display: flex;
flex-direction: row;
flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 3rem;
    position: relative;
  }
  
  .contact-info-text {
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .made-by-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
        row-gap: 2rem;
        column-gap: 2rem;
        position: relative;

        align-items: center;

  }
  
  .made-by-text {
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .made-by-details {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 20px;
    height: 83px;
    justify-content: center;
    position: relative;
    /* width: 242px; */
  }
  
  .made-by-image {
    position: relative;
    width: 50px;
  }
  
  .made-by-name {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: fit-content;
  }
  
  .made-by-vector {
    height: 50px;
    position: relative;
    width: 35px;
  }
  