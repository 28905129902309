.worktab-container {
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-bottom: 3rem;
  max-width: 70rem;
}

.worktab-frame {
  background-color: #ffffe3;
  border: 2px solid;
  border-color: #000000;
  border-radius: 50px;
  box-shadow: var(--shadow-2);
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  align-items: center;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 5vh;
  padding-bottom: 5vh;
  position: relative;
  animation: shadowChange1 4s infinite;
}


@keyframes shadowChange1 {
  0% {
    box-shadow: 10px 10px #bafbb0;
  }
  50% {
    box-shadow: 10px 10px #e7e765;
  }
  100% {
    box-shadow: 10px 10px #bafbb0;
  }
}

.worktab-content {
  align-items: center;
  display: flex;
  row-gap: 5vw;
  column-gap: 3rem;
}

@media (max-width: 800px) {
  .worktab-content {
    flex-direction: column;
  }
}
@media (min-width: 801px) {
  .worktab-content {
    flex-direction: row;
  }
}

.worktab-left-container {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  gap: 2rem;
  align-items: left;
  text-align: left;
}

.worktab-title {
  gap: 10px;
  font-weight: 600;
  color: #000000;
  font-size: 4rem;
}

@media (max-width: 768px) {
  .worktab-title {
    font-size: 3rem;
  }
}
@media (max-width: 450px) {
  .worktab-title {
    font-size: 2rem;
  }
}
@media (max-width: 768px) {
  .frame-5 {
    text-align: center;
  }
}

.worktab-right-container {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4rem;
  justify-content: center;
  max-width: 380px;
  position: relative;
}

@media (max-width: 1200px) {
  .worktab-right-container {
    gap: 2rem;
  }
}

.worktab-description {
  color: #000000;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: left;
  width: 100%;
}

@media (max-width: 900px) {
  .worktab-description {
    font-size: 22px;
  }
}

.worktab-button {
  all: unset;
  align-items: center;
  background-color: #000000;
  border-radius: 30px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2vw;
  justify-content: center;
  padding: 14px 26px;
  position: relative;
  cursor: pointer;
}



.worktab-button-title {
  color: #ffffe3;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
  white-space: nowrap;
}

.carousel-arrow {
  background: transparent;
  border: none;
  cursor: none;
  outline: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #ffffe3;
  background-color: #000000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
  line-height: 1;
}

.carousel-arrow.prev {
  left: 2%;
  fill: #ffffe3;
}

.carousel-arrow.next {
  right: 2%;
  fill: #ffffe3;
}

.carousel-arrow:hover {
  background-color: #bafbb0;
  color: #000000;
  fill: #000;
}

.arrow-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;

}

/* .arrow-icon.prev::before {
  content: "←";
  font-style: normal;
}

.arrow-icon.next::before {
  content: "→";
  font-style: normal;
} */

@media (max-width: 1000px) {
  .circled-lottie {
    display: none;
  }
}

.worktab-carousel-container {
  position: relative;
  align-self: center;
  height: 25vw;
  width: 50vw;
  max-height: 300px;
  max-height: 300px;
  max-width: 600px;
  min-width: 200px;
  min-height: 70px;
}

h1 {
  font-size: 4rem;
  margin-bottom: 3rem;
}

.full-work-div {
  justify-content: left;
  text-align: left;
  padding: 3rem;
  display: flex;
  z-index: 0;
  flex-direction: column;
}

@media (max-width: 768px) {
  .full-work-div {
    text-align: center;
  }
}

