.download-button-container {
  gap: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding-bottom: 3rem;
}

.download-button-content {
  height: 100%;
  width: 100%;
  display: flex;
  gap: 3vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.download-button-image {
  padding-right: 3rem;
  padding-left: 3rem;

  max-width: 616px;
}

.pdf-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 40px;
  border: 2px #000 solid;
}

.cv-title-text {
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
}

.download-button-frame {
  background-color: #ffffe3;
  border: 2px solid;
  border-color: #000000;
  border-radius: 50px;
  box-shadow: var(--shadow-2);
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  position: relative;
  animation: shadowChange1 4s infinite;
}

@media (max-width: 800px) {
  .download-button-frame {
    flex-direction: column;
  }
}
@media (min-width: 801px) {
  .download-button-frame {
    flex-direction: row;
  }
}
