.ticker-container-black{
  background-color: black;
  overflow: hidden; /* Ensure the container clips text properly */
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center; /* Center the text vertically */
  white-space: nowrap; /* Prevent line breaks */
}


.ticker-text-black {
  color: #ffffe3;
  font-size: 1.5rem;
  font-weight: 400;
  padding: 1rem 0 1rem 0;
  display: inline-block;
  animation: tickerblack 10s linear infinite;
}

@keyframes tickerblack {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}