.ticker-container-white{
  background-color: #bafaaf;
  overflow: hidden; /* Ensure the container clips text properly */
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center; /* Center the text vertically */
  white-space: nowrap; /* Prevent line breaks */
  margin-top: 1rem;
  border-top: #000 solid 2px;
  border-bottom: #000 solid 2px;
}


.ticker-text-white {
  color: #000;
  font-size: 1.5rem;
  font-weight: 400;
  padding: 1rem 0 1rem 0;
  display: inline-block;
  animation: tickerwhite 25s linear infinite;
}

@keyframes tickerwhite {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}