.green-tab {
  align-items: center;
  background-color: #bafaaf;
  border: 2px solid;
  border-color: #000000;
  border-radius: 50px;
  display: inline-flex;
  font-size: 1.5rem;
  font-weight: 500;
  justify-content: center;
  overflow: hidden;
  padding: 1rem 2rem;
  position: relative;
  box-shadow: 3px 3px #000;
}

.transparent-tab {
  align-items: center;
  border: 2px solid;
  border-color: #000000;
  border-radius: 50px;
  display: inline-flex;
  font-size: 1.5rem;
  font-weight: 500;
  justify-content: center;
  overflow: hidden;
  padding: 1rem 2rem;
  position: relative;
  box-shadow: 3px 3px #000;
}

.green-tab:hover, .transparent-tab:hover {
  box-shadow: 5px 5px #299e17, 10px 10px #e1e12f;
  transition: 0.3s ease;
}
.green-tab:active, .transparent-tab:active {
  background: #000;
  transition: 0.1s ease;
  color: #ffffe3;
}

.what-i-can-do {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  text-align: left;
}

.what-i-can-do-text {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 3rem;
  justify-content: left;
  align-items: left;
  size: 100%;
}

.green-tab-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

}

.transparent-tab-group {
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}


@media (max-width: 768px) {
  .green-tab-group {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .transparent-tab-group {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .what-i-can-do {
    text-align: center;
  }
}